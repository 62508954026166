import React from "react"
import Result from "./Result"


export default function ResultList({ posts }) {
  return (
    <>
      {posts.map(post => {
        return <Result data={post} key={post.uri}/>
      })}
    </>
  )
}
