import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const PostCard = styled.div`
  min-width: 300px;
  width: 100%;
  padding: 18px 18px 18px 18px;
  box-sizing: border-box;
  border: 1px solid #e7ebed;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  margin: 18px 18px 12px 18px;
  transition: 0.3s ease-in-out;
  // transform: scale(1) translate3d(0, 0, 0) perspective(1px);
  // transform-origin: left top;

  @media only screen and (max-width: 746px) {
    box-shadow: rgb(50 50 50 / 10%) 0px 0px 11px 0px;
    width: 100%;
  }

  @media only screen and (min-width: 747px) {
    &:hover {
      box-shadow: rgb(50 50 50 / 30%) 0px 0px 11px 0px;
      //transform: translateY(-10px) scale(1) translate3d(0, 0, 0) perspective(1px);
      //transform-origin: left top;
    }
  }

  img {
    width: 100%;
  }
`

const InfoBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
}
`

const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: flext-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  max-width: calc((100% * (5 / 13)) - 1rem); //Golden Ratio
  flex-wrap: wrap;
  border-radius: 5px;
  height: fit-content;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`

const TextContainer = styled.div`
  max-width: calc((100% * (8 / 13)) - 1rem); //Golden Ratio

  @media only screen and (max-width: 600px) {
    max-width: 100%;
}
`

const PostTitle = styled.div`
  margin: 0 0 8px 0;
  width: 100%;

  a {
    text-decoration: none;
    color: #11171a;
  }

  a:hover {
    color: #11171a;
    text-decoration: underline;
  }

  h2 {
    line-height: 1.4;
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 500;
    margin-top: -3px;
  }
`

const AuthorDate = styled.p`
  color: #ADADAD;
  font-size: 1.2rem;
  margin-bottom: 10px;
  letter-spacing: 0.1px;

  a {
    color: #ADADAD;
    text-decoration: none;
  }
`

const Excerpt = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #444;
  letter-spacing: 0.1px;
`

const ReadMoreLink = styled.a`
  font-size: 15px;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: #0066CC;
  }
`

export default function Result({ data }) {
  const post = data
  const title = post.title
  const image = getImage(post?.featuredImage?.node?.localFile)

  return (
    <PostCard key={post.uri}>
      <article itemScope itemType="http://schema.org/Article">
        <InfoBlock>
          <ImageContainer>
            <Link to={post.uri}>
              
              {image !== undefined 
                        ? <GatsbyImage alt={post?.featuredImage?.node?.altText} image={image} />
                        : <></>}
            </Link>
            
          </ImageContainer>

          <TextContainer>
            <PostTitle>
              <header>
                <h2>
                  <Link to={post.uri} itemProp="url">
                    <span itemProp="headline">{parse(title)}</span>
                  </Link>
                </h2>
              </header>
            </PostTitle>
            <AuthorDate><Link to={post.author.node.uri}>{post.author.node.name}</Link> | {post.date}</AuthorDate>
            <section itemProp="description">
              <Excerpt>{parse(post.excerpt)}</Excerpt>
            </section>

            <ReadMoreLink href={post.uri}>Read More »</ReadMoreLink>
          </TextContainer>
        </InfoBlock>
      </article>
    </PostCard>
  )
}
