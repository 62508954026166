import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
background-color: #6bc8c6; //EWL Blue
width: 100%;
margin: 0;
padding: 20px 0;
display: flex;
justify-content: center;
word-break: break-word;
overflow: hidden;
max-height: 9rem;
`

const H1 = styled.h1`
color: white;
margin: 0 auto;
line-height: 1.1;
font-weight: 500;
padding: 0 15px;
text-align: center;

@media only screen and (min-width: 0px) {
    font-size: 2.2rem;
}
@media only screen and (min-width: 768px) {
    font-size: 3rem;
}
@media only screen and (min-width: 1026px) {
    font-size: 3.6rem;
}
`

export default function Hotbar({title}) {

    
    return (
        <Div>
            <H1>{title}</H1>
        </Div>
    )
}
